import React from 'react';
import { useTranslation } from 'react-i18next';
import './CasesPage.css';

import SchedulingFigure from '../../assets/images/cases/scheduling.png';
import WorkingFigure from '../../assets/images/cases/working.png';
import StudyingFigure from '../../assets/images/cases/studying.png';
import CollaboratingFigure from '../../assets/images/cases/collaborating.png';
import PlayingFigure from '../../assets/images/cases/playing.png';
import SignTranslation from '../../assets/images/cases/sign_translation.png';

function CasesPage() {
    const { t } = useTranslation();
    return (
        <div className='CasesPage'>
            <div className='case-title-background'>
                <div className='case-title-container'>
                    <h1>{t('cases.title')}</h1>
                </div>
            </div>
            <div className='cases-roll'>
                {/* <div className='scheduling-image-container'>
                    <img src={SchedulingFigure} alt='Scheduling' />
                </div>
                <div className='case-container case-container-1'>
                    <p className='case'>{t('cases.case.1')}</p>
                </div>
                <div className='case-container case-container-2'>
                    <p className='case'>{t('cases.case.2')}</p>
                </div>
                <div className='case-container case-container-3'>
                    <p className='case'>{t('cases.case.3')}</p>
                </div>
                <div className='working-image-container'>
                    <img src={WorkingFigure} alt='Working' />
                </div>
                <div className='case-container case-container-4'>
                    <p className='case'>{t('cases.case.4')}</p>
                </div>
                <div className='case-container case-container-5'>
                    <p className='case'>{t('cases.case.5')}</p>
                </div>
                <div className='case-container case-container-6'>
                    <p className='case'>{t('cases.case.6')}</p>
                </div>
                <div className='study-image-container'>
                    <img src={StudyingFigure} alt='Studying' />
                </div>
                <div className='case-container case-container-7'>
                    <p className='case'>{t('cases.case.7')}</p>
                </div>
                <div className='case-container case-container-8'>
                    <p className='case'>{t('cases.case.8')}</p>
                </div>
                <div className='collaborating-image-container'>
                    <img src={CollaboratingFigure} alt='Collaborating' />
                </div>
                <div className='case-container case-container-9'>
                    <p className='case'>{t('cases.case.9')}</p>
                </div>
                <div className='case-container case-container-10'>
                    <p className='case'>{t('cases.case.10')}</p>
                </div>
                <div className='playing-image-container'>
                    <img src={PlayingFigure} alt='Playing' />
                </div> */}
                <div className='case-container case-container-11'>
                    <p className='case'>{t('cases.case.11')}</p>
                </div>
                <div className='case-container case-container-12'>
                    <p className='case'>{t('cases.case.12')}</p>
                </div>
                <div className='case-container case-container-13'>
                    <p className='case'>{t('cases.case.13.first')}</p>
                    <ul>
                        <li>{t('cases.case.13.key1')}</li>
                        <li>{t('cases.case.13.key2')}</li>
                        <li>{t('cases.case.13.key3')}</li>
                        <li>{t('cases.case.13.key4')}</li>
                    </ul>
                    <p className='case'>{t('cases.case.13.last')}</p>
                </div>
                <div className='sign-language-image-container'>
                    <img src={SignTranslation} alt='Playing' />
                </div>
                <div className='case-container case-container-14'>
                    <p className='case'>{t('cases.case.14')}</p>
                </div>
                <div className='case-container case-container-15'>
                    <p className='case'>{t('cases.case.15')}</p>
                </div>
                {/* <div className='eye-tracking-image-container'>
                    <img src={PlayingFigure} alt='Playing' />
                </div> */}
                <div className='case-container case-container-16'>
                    <p className='case'>{t('cases.case.16')}</p>
                </div>
                <div className='case-container case-container-17'>
                    <p className='case'>{t('cases.case.17')}</p>
                </div>
                {/* <div className='dysarthic-speech-image-container'>
                    <img src={PlayingFigure} alt='Playing' />
                </div> */}
            </div>
        </div>
    );
}

export default CasesPage;