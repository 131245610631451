import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { collection, getDocs, addDoc, query, limit, where, orderBy } from "firebase/firestore";
import { db } from '../../../firebase';
import './ContactForm.css';

const ContactForm = () => {
    const { t } = useTranslation();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [sentAt, setSentAt] = useState('');
    const [agreement, setAgreement] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const getTimeString = () => {
        return (new Date()).toISOString();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (message.length < 10) {
            setSuccessMessage('');
            setAgreement(false);
            setErrorMessage(t('contact.messageLimitError'));
            return;
        }

        try {
            const whereQuery = query(
                collection(db, 'direct_contacts'),
                where('email', '==', email),
                orderBy('sentAt', 'desc'),
                limit(1),
            );

            const querySnapshot = await getDocs(whereQuery);
            if (!querySnapshot.empty) {
                let lastContact = querySnapshot.docs[0].data();
                let lastContactTime = new Date(lastContact.sentAt);
                let currentTime = new Date();
                let timeDiff = currentTime.getTime() - lastContactTime.getTime();
                let diffHours = Math.ceil(timeDiff / (1000 * 3600));
                if (diffHours < 4) {
                    setSuccessMessage('');
                    setAgreement(false);
                    setErrorMessage(t('contact.alreadyContactedError'));
                    return;
                }
            }

            setSentAt(getTimeString());
            const applicationData = {
                fullName,
                email,
                message,
                agreement,
                sentAt,
            };

            const docRef = await addDoc(collection(db, 'direct_contacts'), applicationData);

            setErrorMessage('');
            setSuccessMessage(t('contact.success'));
            setFullName('');
            setEmail('');
            setMessage('');
            setAgreement(false);
            console.log('Application submitted with ID:', docRef.id);
        } catch (error) {
            setSuccessMessage(t(''));
            setAgreement(false);
            setErrorMessage(t('contact.genericError'));
            console.error('Error submitting application:', error);
        }
    };

    return (
        <form className="ContactForm" onSubmit={handleSubmit}>
            {/* Contact Information */}
            <div className="form-input">
                <label htmlFor="fullName">{t('contact.fullName')}:</label>
                <input
                    type="text"
                    id="fullName"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                />
            </div>
            <div className="form-input">
                <label htmlFor="email">
                    {t('contact.email')}:
                </label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div className="form-input">
                <label htmlFor="additionalInfo">
                    {t('contact.yourMessage')}
                </label>
                    <textarea
                        id="additionalInfo"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        maxLength={200}
                        minLength={10}
                        required
                    ></textarea>
                    <label htmlFor="additionalInfo">
                        {t('contact.messageLimit')}
                    </label>
                </div>
            {/* Agreement */}
            <div className="agreement">
                <input
                    type="checkbox"
                    id="agreement-checkbox"
                    checked={agreement}
                    onChange={(e) => setAgreement(e.target.checked)}
                    required
                />
                <label htmlFor="agreement-checkbox">
                    {t('contact.agreement')}
                </label>
            </div>
            {/* Submit Button */}
            {successMessage && <div className="success-message">{successMessage}</div>}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {!successMessage && !errorMessage && <div className="message-span"></div>}
            <button type="submit">{t('contact.submit')}</button>
        </form >
    );
};

export default ContactForm;
