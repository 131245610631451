import "./Footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin }
  from '@fortawesome/free-brands-svg-icons';

import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  const buildSocialMediaIcon = (icon, link) => {
    return (
      <Link to={link} className='FooterSocialIconLink' target='_blank' >
        <FontAwesomeIcon className="FooterSocialIcon" icon={icon} />
      </Link>
    );
  }
  return (
    <div className="Footer">
      <div className="FooterCopyright">
        {t('footer.copyRight')}
      </div>
      <Link to='/contact' className='ContactLink' >
        <div className="FooterContact">
          <FontAwesomeIcon className="FooterSocialIcon" icon={faEnvelope} />
          <div className="FooterContactText">
            {t('footer.contactUs')}
          </div>
        </div>
      </Link>
      <div className="FooterSocialMedia">
        {/* {buildSocialMediaIcon(faInstagram, 'https://www.instagram.com/yeterly_official/')}
        {buildSocialMediaIcon(faYoutube, 'https://www.youtube.com/channel/UCrstjUD4Cb7IxMGtNhFvBuw')}
        {buildSocialMediaIcon(faFacebook, 'https://www.facebook.com/people/Yeterly/100083556334797/')}
        {buildSocialMediaIcon(faTwitter, 'https://twitter.com/yeterly_app')} */}
        {buildSocialMediaIcon(faLinkedin, 'https://www.linkedin.com/company/yeterlyofficial/')}
      </div>
    </div>
  );
}

export default Footer;