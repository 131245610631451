import { useTranslation } from 'react-i18next';
import './LandingIntroduction.css';

import AIBrainFigure from '../../../assets/images/landing/ai-brain.png';
import WorkingFigure from '../../../assets/images/landing/working.png';
import GUIFigure from '../../../assets/images/landing/gui.png';
import TasksFigure from '../../../assets/images/landing/tasks.png';

function LandingIntroduction() {
    const { t } = useTranslation();
    return (
        <div className='LandingIntroduction'>
          <div className='introduction-column'>
            <p style={{ width: '80%', marginTop: '5%', marginBottom: '5%', textAlign: 'left' }}>
              {t('landing.introduction.text.1')}
            </p>
            <img src={AIBrainFigure} alt='AI Brain' className='brain-image' />
            <p>
              <h4>{t('landing.introduction.whatWeDo.title')}</h4>
              <ul className='introduction-column-list'>
                <li>{t('landing.introduction.whatWeDo.item.1')}</li>
                <li>{t('landing.introduction.whatWeDo.item.2')}</li>
                <li>{t('landing.introduction.whatWeDo.item.3')}</li>
                <li>{t('landing.introduction.whatWeDo.item.4')}</li>
                <li>{t('landing.introduction.whatWeDo.item.5')}</li>
                <li>{t('landing.introduction.whatWeDo.item.6')}</li>
                <li>{t('landing.introduction.whatWeDo.item.7')}</li>
                <li>{t('landing.introduction.whatWeDo.item.8')}</li>
                <li>{t('landing.introduction.whatWeDo.item.9')}</li>
                <li>{t('landing.introduction.whatWeDo.item.10')}</li>
                <li>{t('landing.introduction.whatWeDo.item.11')}</li>
              </ul>
            </p>
          </div>
          <div className='introduction-column'>
            <div className='introduction-img-row'>
              <img src={WorkingFigure} alt='WorkingFigure' className='working-image' />
              <img src={GUIFigure} alt='GUIFigure' className='gui-image' />
            </div>
            <p className='introduction-column-2-p' >{t('landing.introduction.text.2')}</p>
            <img src={TasksFigure} alt='TasksFigure' className='tasks-image' />
          </div>
        </div>
    );
}

export default LandingIntroduction;