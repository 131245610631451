import React from 'react';
import { useTranslation } from 'react-i18next';
import './Landing.css';
import LandingIntroduction from './introduction/LandingIntroduction';
import Section2 from './section-2/Section2';
import Section3 from './section-3/Section3';
import Section4 from './section-4/Section4';

function LandingPage() {
  const { t } = useTranslation();
  return (
    <div className='LandingPage'>
      <div className='landing-title-background'>
        <div className='landing-title-container'>
          <h1>{t('landing.title')}</h1>
          <h2>{t('landing.subtitle')}</h2>
        </div>
      </div>
      <LandingIntroduction />
      <Section2 />
      <Section3 />
      <Section4 />
      </div>
  );
}

export default LandingPage;
