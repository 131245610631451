import React from 'react';
import './App.css';
import MainContainer from "./components/mainContainer/MainContainer";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/landing/Landing';
import ServicesPage from './pages/services/ServicesPage';
import CasesPage from './pages/cases/CasesPage';
import ContactPage from './pages/contact/Contact';

function App() {
  return (
    <Router>
      <div className="App">
        <MainContainer >
          <Routes>
            <Route path="/" exact element={<LandingPage />}></Route>
            <Route path="/services" element={<ServicesPage />}></Route>
            <Route path="/cases" element={<CasesPage />}></Route>
            <Route path="/contact" element={<ContactPage />}></Route>
          </Routes>
        </MainContainer>
      </div>
    </Router>
  );
}

export default App;
