
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const config = {
    apiKey: "AIzaSyDAvXqcAIjGfrajxpxy8oc8hJOMXhCOcqA",
    authDomain: "ai-website-4d3d9.firebaseapp.com",
    projectId: "ai-website-4d3d9",
    storageBucket: "ai-website-4d3d9.appspot.com",
    messagingSenderId: "68572827032",
    appId: "1:68572827032:web:d7f757f0c1a0c451dfafed",
    measurementId: "G-YFTLRTY2Y4"
};

let app;
if (!getApps().length) {
   app = initializeApp(config);
}

export const db = getFirestore(app);