import { useTranslation } from 'react-i18next';
import './Section3.css';

import DesignFigure from '../../../assets/images/landing/design.png';
import Texting2Figure from '../../../assets/images/landing/texting-2.png';

function Section3() {
    const { t } = useTranslation();

    return (
        <div className='section-3'>
            <div className='section-3-text-1-container' >
                <h3>{t('landing.untaggedContent.title')}</h3>
                <p>{t('landing.untaggedContent.text')}</p>
            </div>
            <div className='section-3-text-2-container' >
                <ul>
                    <li>{t('landing.untaggedContent.item.1')}</li>
                    <li>{t('landing.untaggedContent.item.2')}</li>
                    <li>{t('landing.untaggedContent.item.3')}</li>
                    <li>{t('landing.untaggedContent.item.4')}</li>
                    <li>{t('landing.untaggedContent.item.5')}</li>
                </ul>
            </div>
            <div className='design-container' >
                <img
                    className='section-3-image'
                    src={DesignFigure}
                    alt='DesignFigure'
                />
            </div>
            <div className='section-3-text-3-container'>
                <h3>{t('landing.statisticalLanguageProcessing.title')}</h3>
                <p>
                    {t('landing.statisticalLanguageProcessing.text')}
                    <ul>
                        <li>{t('landing.statisticalLanguageProcessing.item.1')}</li>
                        <li>{t('landing.statisticalLanguageProcessing.item.2')}</li>
                        <li>{t('landing.statisticalLanguageProcessing.item.3')}</li>
                        <li>{t('landing.statisticalLanguageProcessing.item.4')}</li>
                        <li>{t('landing.statisticalLanguageProcessing.item.5')}</li>
                        <li>{t('landing.statisticalLanguageProcessing.item.6')}</li>
                    </ul>
                </p>
            </div>
            <div className='texting-2-container' >
                <img
                    className='section-3-image'
                    src={Texting2Figure}
                    alt='Texting1Figure'
                />
            </div>
        </div>
    );
}

export default Section3;