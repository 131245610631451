import { useTranslation } from 'react-i18next';
import './Section2.css';

import TrioFigure from '../../../assets/images/landing/trio.png';
import Texting1Figure from '../../../assets/images/landing/texting-1.png';

function Section2() {
    const { t } = useTranslation();

    return (
        <div className='section-2'>
            <div className='trio-container' >
                <img
                    className='section-2-image'
                    src={TrioFigure}
                    alt='TrioFigure'
                />
            </div>
            <div className='section-2-text-1-container'>
                <h3>{t('landing.queryUnderstanding.title')}</h3>
                <p>{t('landing.queryUnderstanding.text')}</p>
            </div>
            <div className='section-2-text-2-container' >
                <h3>{t('landing.rawLanguageProcessing.title')}</h3>
                <p>
                    {t('landing.rawLanguageProcessing.text')}
                    <ul>
                        <li>{t('landing.rawLanguageProcessing.item.1')}</li>
                        <li>{t('landing.rawLanguageProcessing.item.2')}</li>
                        <li>{t('landing.rawLanguageProcessing.item.3')}</li>
                        <li>{t('landing.rawLanguageProcessing.item.4')}</li>
                        <li>{t('landing.rawLanguageProcessing.item.5')}</li>
                        <li>{t('landing.rawLanguageProcessing.item.6')}</li>
                    </ul>
                </p>
            </div>
            <div className='texting-container' >
                <img
                    className='section-2-image'
                    src={Texting1Figure}
                    alt='Texting1Figure'
                />
            </div>
        </div>
    );
}

export default Section2;