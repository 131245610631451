import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === 'tr' ? 'en' : 'tr';
    i18n.changeLanguage(newLanguage);
  };

  const currentLanguage = i18n.language;

  return (
    <div className="language-switcher-container" onClick={changeLanguage} >
      {currentLanguage === 'tr' ? 'EN' : 'TR'}
    </div>
  );
}

export default LanguageSwitcher;
