import React from 'react';
import { useTranslation } from 'react-i18next';
import './ServicesTitle.css';

function ServicesTitle() {
    const { t } = useTranslation();
    return (
        <div className="ServicesTitle">
            <div className='services-title-container'>
                <h1>{t('services.title')}</h1>
                <p className='services-subtitle'>
                    <h2>{t('services.subtitle.line.1')}</h2>
                    <h2>{t('services.subtitle.line.2')}</h2>
                </p>
                <div className='services-main-categories'>
                    <div className='services-main-category'>
                        <h3>{t('services.mainCategory.1')}</h3>
                    </div>
                    <div className='services-main-category'>
                        <h3>{t('services.mainCategory.2')}</h3>
                    </div>
                    <div className='services-main-category'>
                        <h3>{t('services.mainCategory.3')}</h3>
                    </div>
                    <div className='services-main-category'>
                        <h3>{t('services.mainCategory.4')}</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesTitle;