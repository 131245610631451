import React from 'react';
import { useTranslation } from 'react-i18next';
import './ServicesGrid.css';

import HumanFigure from '../../../assets/images/services/human.png';
import RobotFigure from '../../../assets/images/services/robot.png';

function ServicesGrid() {
    const { t } = useTranslation();
    return (
        <div className="ServicesGrid">
            <div className='services-grid-title-container'>
                <h1>{t('services.grid.title')}</h1>
            </div>
            <div className='services-grid'>
                {/* row 1 */}
                <div className='services-grid-item item-1-1'>
                    <strong>{t('services.grid.item.1.1.title')}</strong>
                    <p>{t('services.grid.item.1.1.text')}</p>
                </div>
                <div className='services-grid-item item-1-2'>
                    <strong>{t('services.grid.item.1.2.title')}</strong>
                    <p>{t('services.grid.item.1.2.text')}</p>
                </div>
                <div className='services-grid-item item-1-3'>
                    <strong>{t('services.grid.item.1.3.title')}</strong>
                    <p>{t('services.grid.item.1.3.text')}</p>
                </div>
                {/* row 2 */}
                <div className='services-grid-item item-2-1'>
                    <strong>{t('services.grid.item.2.1.title')}</strong>
                    <p>{t('services.grid.item.2.1.text')}</p>
                </div>
                <div className='services-grid-item item-2-2'>
                    <strong>{t('services.grid.item.2.2.title')}</strong>
                    <p>{t('services.grid.item.2.2.text')}</p>
                </div>
                <div className='services-grid-item item-2-3'>
                    <div className='services-human-image-container'>
                        <img src={HumanFigure} alt='Human' />
                    </div>
                    <div className='services-robot-image-container'>
                        <img src={RobotFigure} alt='Robot' />
                    </div>
                </div>
                {/* row 3 */}
                <div className='services-grid-item item-3-1'>
                    <strong>{t('services.grid.item.3.1.title')}</strong>
                    <p>{t('services.grid.item.3.1.text')}</p>
                </div>
                <div className='services-grid-item item-3-2'>
                    <strong>{t('services.grid.item.3.2.title')}</strong>
                    <p>{t('services.grid.item.3.2.text')}</p>
                </div>
                {/* row 4 */}
                <div className='services-grid-item item-4-1'>
                    <strong>{t('services.grid.item.4.1.title')}</strong>
                    <p>{t('services.grid.item.4.1.text')}</p>
                </div>
                <div className='services-grid-item item-4-2'>
                    <strong>{t('services.grid.item.4.2.title')}</strong>
                    <p>{t('services.grid.item.4.2.text')}</p>
                </div>
                <div className='services-grid-item item-4-3'>
                    <strong>{t('services.grid.item.4.3.title')}</strong>
                    <p>{t('services.grid.item.4.3.text')}</p>
                </div>
            </div>
        </div>
    );
}

export default ServicesGrid;