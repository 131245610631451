import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from './contact-form/ContactForm';
import './Contact.css';

import CommunicationFigure from '../../assets/images/contact/communication.png';
import IdeaFigure from '../../assets/images/contact/idea.png';

const ContactPage = () => {
    const { t } = useTranslation();
    return (
        <div className="ContactPage" >
            <div className='description-column'>
                <h1 className='contact-title'>{t('contact.title')}</h1>
                <p className='contact-text-1'>{t('contact.text.1')}</p>
                <div className='contact-text-container'>
                    <p className='contact-text-2'>{t('contact.text.2')}</p>
                    <div className='contact-idea-image-container'>
                        <img src={IdeaFigure} alt='Idea' />
                    </div>
                    <p className='contact-text-3'>{t('contact.text.3')}</p>
                </div>
            </div>
            <div className='form-column'>
                <div className='contact-communication-image-container'>
                    <img src={CommunicationFigure} alt='Communication' />
                </div>
                <ContactForm />
            </div>
        </div>
    );
};

export default ContactPage;