import { useTranslation } from 'react-i18next';
import './Section4.css';

import CollaborationFigure from '../../../assets/images/landing/collaboration.png'

function Section4() {
    const { t } = useTranslation();

    return (
        <div className='section-4'>
            <div className='section-4-title-container' >
                <h3>{t('landing.featuresLanguageProcessing.title')}</h3>
            </div>
            <div className='section-4-text-container' >
                <div className='section-4-item-container' >
                    <p>
                        <strong>
                            {t('landing.featuresLanguageProcessing.item.1.title')}
                        </strong>
                        <br />
                        {t('landing.featuresLanguageProcessing.item.1.text')}
                    </p>
                </div>
                <div className='section-4-item-container' >
                    <p>
                        <strong>
                            {t('landing.featuresLanguageProcessing.item.2.title')}
                        </strong>
                        <br />
                        {t('landing.featuresLanguageProcessing.item.2.text')}
                    </p>
                </div>
                <div className='section-4-item-container' >
                    <p>
                        <strong>
                            {t('landing.featuresLanguageProcessing.item.3.title')}
                        </strong>
                        <br />
                        {t('landing.featuresLanguageProcessing.item.3.text')}
                    </p>
                </div>
            </div >
            <img
                className='section-4-image'
                src={CollaborationFigure}
                alt='TrioFigure'
            />
        </div>
    );
}

export default Section4;